import axiosActions from "../actions/axiosActions";
import { API_URL } from "../constants";
import { RouteStatus } from "../models/enums/routeStatus";
import { Response } from "../models/response";
import { Route } from "../models/routes/route";

interface RoutesService {
    get: () => Promise<Response<Record<string, Route[]>>>;
    getRoute: (routeId: string) => Promise<Response<Route>>;
    setStatus: (routeId: string, status: RouteStatus, childStatus?: RouteStatus, statusComments?: string,) => Promise<Response<Route>>;
}

const get = async () => {
    const response = await axiosActions.getAsync<Record<string, Route[]>>(API_URL.routes.get);
    return response;
};

const getRoute = async (routeId: string) => {
    const response = await axiosActions.getAsync<Route>(`${API_URL.routes.get}/${routeId}`);
    return response;
};

const setStatus = async (routeId: string, status: RouteStatus, childStatus?: RouteStatus, statusComments?: string,) => {
    const request: Record<string, string> = {
        Status: status.toString(),
        ...(statusComments && { StatusComments: statusComments }),
        ...(childStatus && { ChildStatus: childStatus.toString() })
    };

    const response = await axiosActions.patchAsync<Record<string, string>, Route>(`${API_URL.routes.patch}/${routeId}`, request);
    return response;
};

const routesService: RoutesService = { get, getRoute, setStatus };

export default routesService;
