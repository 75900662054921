import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "../../fontawesome/pro-regular-svg-icons";
import clsx from "clsx";
import { FormEvent } from "react";
import { Button } from "reactstrap";

export type LoadingButtonProps = {
    caption: string;
    loading: boolean;
    disabled?: boolean;
    size?: "lg" | "sm" | " ";
    color?: string;
    type?: "submit" | "reset" | "button";
    className?: string;
    onClick?: (event: FormEvent) => void;
};

const LoadingButton = ({ caption, loading, disabled, size, color, type, className, onClick }: LoadingButtonProps) => {
    return (
        <Button
            color={color}
            type={type}
            disabled={disabled || loading}
            size={size}
            className={clsx(className)}
            onClick={(event) => onClick && onClick(event)}
        >
            {/* TODO: Сделать более красивый эффект загрузки */}
            {loading && <FontAwesomeIcon icon={faSpinner} spin className="me-1" />}
            {caption}
        </Button>
    );
};

export default LoadingButton;
