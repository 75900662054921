import clsx from "clsx";
import ImageGallery from "../../../components/containers/imageGallery";
import ListItemCard from "../../../components/containers/listItemCard";
import FileInput from "../../../components/inputs/fileInput";
import { CheckListParameters, isInputAction, mayBeClosed } from "../../../models/routes/checkListParameters";
import InputAction from "./inputAction";
import useDependenciesChange from "../../../hooks/useDependenciesChange";
import { FileInfo } from "../../../models/routes/fileInfo";
import { pluralFile } from "../../../lib/heplers/plural";

const getFileInputParams = (type: string) => {
    switch (type) {
        case "Photo":
            return { accept: "image/*", caption: "Добавить фотографии" };
        case "Video":
            return { accept: "video/*", caption: "Добавить видео" };
        default:
            return { accept: "image/*, video/*", caption: "Добавить фото или видео" };
    }
};

type Props = {
    parameters: CheckListParameters;
    actionNotAvailable: boolean;
    onAddEvent: (eventActionType: string) => Promise<void>;
    onAddFiles: (files: File[], parameters: CheckListParameters) => AsyncGenerator<number, void, unknown>;
    onRemoveFiles?: (file: FileInfo[]) => Promise<void>;
    onSaveActionParams: (changes: Record<string, string>) => Promise<void>;
};

const routePointCardClassName = "border-start border-5";

const CheckListAction = ({
    parameters,
    actionNotAvailable,
    onAddEvent,
    onAddFiles,
    onRemoveFiles,
    onSaveActionParams,
}: Props) => {
    const { accept, caption } = getFileInputParams(parameters.type);

    const pointMayBeClosed = mayBeClosed(parameters);
    const buttonColor = actionNotAvailable ? undefined : "primary";
    const files = parameters.files || [];

    useDependenciesChange(() => {
        if (parameters.eventActionType && Boolean(mayBeClosed(parameters))) {
            (async () => await onAddEvent(parameters.eventActionType!))();
        }
    }, [Boolean(mayBeClosed(parameters))]);

    return (
        <ListItemCard
            className={clsx(
                "my-3",
                routePointCardClassName,
                actionNotAvailable ? "border-secondary" : pointMayBeClosed ? "border-success" : "border-warning",
            )}
        >
            <div className="fw-bold">
                {parameters.n}. {parameters.name}
            </div>
            <div dangerouslySetInnerHTML={{ __html: parameters.description }} />

            {isInputAction(parameters) ? (
                <InputAction
                    parameters={parameters}
                    buttonColor={buttonColor}
                    disabled={actionNotAvailable}
                    onSave={onSaveActionParams}
                />
            ) : (
                <>
                    <FileInput
                        files={files}
                        accept={accept}
                        multiple={true}
                        disabled={actionNotAvailable}
                        className="mt-2"
                        caption={caption}
                        buttonColor={buttonColor}
                        onAddFiles={(files) => onAddFiles(files, parameters)}
                        onRemoveFiles={actionNotAvailable ? undefined : onRemoveFiles}
                        renderFileItems={
                            parameters.type === "Photo"
                                ? (files, onRemoveFiles, disabled) => (
                                      <ImageGallery
                                          images={files}
                                          disabled={disabled}
                                          onRemoveFiles={actionNotAvailable ? undefined : onRemoveFiles}
                                      />
                                  )
                                : undefined
                        }
                    />
                    {parameters.minQty && !pointMayBeClosed && (
                        <div className="text-center small">Осталось добавить {pluralFile(parameters.minQty - files.length)}</div>
                    )}
                </>
            )}
        </ListItemCard>
    );
};

export default CheckListAction;
