import clsx from "clsx";
import { Button, Col, Row } from "reactstrap";
import ListItemCard from "../../components/containers/listItemCard";
import { RoutePointStatus } from "../../models/enums/routePointStatus";
import { RoutePoint } from "../../models/routes/routePoint";
import RoutePointInfo from "./routePointInfo";
import { NextWorksId, Route } from "../../models/routes/route";
import { RouteStatus } from "../../models/enums/routeStatus";

type Props = {
    routePoint: RoutePoint;
    route: Route;
    number: number;
    onOpenPoint: () => void;
};

const routePointCardClassName = "border-start border-5";

const RoutePointView: React.FC<Props> = ({ routePoint, route, number, onOpenPoint }) => {
    const rowClassName = "gx-2";
    const colSize = 3;
    const pointClosed = routePoint.status >= RoutePointStatus.Closed || route.status >= RouteStatus.WaitingForRouteSheet;
    const isSimpleView = route.nextWorksCheckListId === NextWorksId.WaybillAndTimeSpentForBicycles;

    return (
        <div className="my-2">
            <div className="fw-bold">#{number}</div>
            {(routePoint.status === RoutePointStatus.Open || routePoint.status === RoutePointStatus.Cancelled) &&
            !isSimpleView ? (
                <ListItemCard className={clsx(routePointCardClassName, "border-secondary")}>
                    <Row className={rowClassName}>
                        <Col xs={colSize}>Адрес:</Col>
                        <Col className="fw-bold">{routePoint.city}</Col>
                    </Row>
                    {!isSimpleView && (
                        <div className="mt-2 text-center">
                            {routePoint.status === RoutePointStatus.Open
                                ? "Закройте предыдущие адреса маршрута"
                                : "Точка маршрута отменена"}
                        </div>
                    )}
                </ListItemCard>
            ) : (
                <ListItemCard className={clsx(routePointCardClassName, pointClosed ? "border-success" : "border-warning")}>
                    <RoutePointInfo routePoint={routePoint} />
                    {!isSimpleView &&
                        (pointClosed ? (
                            <>
                                <div className="mt-2 text-center fw-bold">Закрыт</div>
                                <Button className="w-100 mt-2" color="secondary" size="sm" onClick={onOpenPoint}>
                                    Просмотреть
                                </Button>
                            </>
                        ) : (
                            <Button className="w-100 mt-2" color="primary" onClick={onOpenPoint}>
                                Я на месте
                            </Button>
                        ))}
                </ListItemCard>
            )}
        </div>
    );
};

export default RoutePointView;
