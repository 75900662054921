import clsx from "clsx";

type Props = {
    children: React.ReactNode;
    className?: string;
};

const ListItemCard: React.FC<Props> = ({ children, className }) => {
    return <div className={clsx("p-2 rounded bg-gradient card-background", className)}>{children}</div>;
};

export default ListItemCard;
