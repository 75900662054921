import { ApiUrlConfig } from "./models/configs/apiUrlConfig";

const API_URL: ApiUrlConfig = {
    auth: {
        checkTimeout: "auth/checkTimeout",
        login: "auth/login",
        signIn: "auth/signIn",
    },
    routes: {
        get: "routes",
        patch: "routes",
    },
    routePoints: {
        controller: "routePoints",
        events: "events",
    },
    attachments: {
        download: "attachments",
        remove: "attachments",
        upload: "attachments/upload",
    },
};

export { API_URL };
