import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * Выполняет callback при изменении какого-либо элемента из dependencies.
 * Не срабатывает после первоначального рендеринга, как дефолтный useEffect
 */
const useDependenciesChange = (callback: EffectCallback, dependencies: DependencyList) => {
    const mounted = useRef(true);

    useEffect(() => {
        if (!mounted.current) return callback();
        mounted.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export default useDependenciesChange;
