import clsx from "clsx";
import { ChangeEvent } from "react";
import { Input } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

type Props = {
    value?: string | number;
    name?: string;
    type?: InputType;
    placeholder?: string;
    size?: "lg" | "sm";
    required?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    min?: number;
    step?: number;
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const UniInput: React.FC<Props> = ({
    value,
    name,
    type,
    placeholder,
    size,
    required,
    disabled,
    invalid,
    min,
    step,
    className,
    onChange,
}) => {
    return (
        <Input
            value={value}
            name={name}
            placeholder={placeholder}
            type={type}
            bsSize={size || "lg"}
            required={required}
            disabled={disabled}
            invalid={invalid}
            min={min}
            step={step}
            className={clsx(className)}
            onChange={onChange}
        />
    );
};

export default UniInput;
