import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Response } from "../models/response";

const JSON_CONFIG: AxiosRequestConfig = {
    responseType: "json",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
};

const FORM_DATA_CONFIG: AxiosRequestConfig = {
    responseType: "json",
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
    },
};

const BLOB_CONFIG: AxiosRequestConfig = {
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
};

interface AxiosActions {
    deleteAsync: <T>(url: string) => Promise<Response<T>>;
    getAsync: <T>(url: string, asBlob?: boolean) => Promise<Response<T>>;
    patchAsync: <T, V>(url: string, body: T) => Promise<Response<V>>;
    postAsync: <T, V>(url: string, body: T) => Promise<Response<V>>;
}

const deleteAsync = async <T>(url: string) => await fetchAsync(() => axios.delete<T>(url, JSON_CONFIG));

const getAsync = async <T>(url: string, asBlob?: boolean) =>
    await fetchAsync(() => axios.get<T>(url, asBlob ? BLOB_CONFIG : JSON_CONFIG));

const postAsync = async <T, V>(url: string, body: T) =>
    await fetchAsync(() => axios.post<V>(url, body, body instanceof FormData ? FORM_DATA_CONFIG : JSON_CONFIG));

const patchAsync = async <T, V>(url: string, body: T) => await fetchAsync(() => axios.patch<V>(url, body, JSON_CONFIG));

const fetchAsync = async <T>(func: () => Promise<AxiosResponse<T, any>>) => {
    try {
        const response = await func();
        return { isOk: true, data: response.data, error: null };
    } catch (error: any) {
        return { isOk: false, data: null, error: error };
    }
};

const axiosActions: AxiosActions = { deleteAsync, getAsync, patchAsync, postAsync };

export default axiosActions;
