import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { Android } from "./models/mobile/android";
import { persistor, store } from "./redux";
import setupAxios from "./setup/setupAxios";
import { ClearCacheProvider } from "react-clear-cache";

const { PUBLIC_URL } = process.env;

declare global {
    interface Window {
        android: Android;
    }
}

setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <ClearCacheProvider duration={20 * 60 * 1000}>
            {/* TODO: Сделать красивый спиннер */}
            <PersistGate persistor={persistor} loading={<div>Загрузка...</div>}>
                <App basename={PUBLIC_URL} />
            </PersistGate>
        </ClearCacheProvider>
    </Provider>,
);
