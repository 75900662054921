import { RouteStatus } from "../enums/routeStatus";
import { RoutePoint } from "./routePoint";
import { FileInfo } from "./fileInfo";

type Route = {
    orderId: string;
    status: RouteStatus;
    statusText: string;
    from: string;
    to: string;
    pointsComment?: string;
    fromAddress: string;
    takeDate: string;
    takeTime: string;
    comments: string;
    points: RoutePoint[];
    nextWorksCheckListId?: string;
    files?: FileInfo[];
};

const startPointSuffix = "_start";

export const NextWorksId = {
    RouteSheetPhoto: "Фото маршрутного листа",
    SignedDocumentsPhotoForBicycles: "Фото подписанных документов",
    WaybillAndTimeSpentForBicycles: "Велосипеды_Время_и_ТН"
};

export const NextWorksIds = Object.values(NextWorksId);

const isRouteByOneOrder = (route: Route) => route.points[0].orderId.endsWith(startPointSuffix);

export { isRouteByOneOrder };

export type { Route };
