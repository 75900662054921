import { FileInfo } from "./fileInfo";

type CheckListParameters = {
    n: string;
    name: string;
    description: string;
    type: string;
    optional: boolean;
    eventActionType?: string;
    attachmentType?: string;
    files?: FileInfo[];
    minQty?: number;
    value?: string;
    propertyName?: string;
};

const isInputAction = (item: CheckListParameters) => item.type === "Number";

const mayBeClosed = (item: CheckListParameters) =>
    item.optional ||
    (isInputAction(item)
        ? item.value && item.value.length > 0
        : item.files && item.files.length > 0 && item.files.length >= (item.minQty ?? 0));

export { isInputAction, mayBeClosed };

export type { CheckListParameters };
