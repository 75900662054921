import { NextWorkCard, NextWorkCardProps } from "./nextWorkCard";

type Props = Omit<NextWorkCardProps, "caption">;

export const ConfirmRouteSheetCard = (props: Props) => {
    return (
        <NextWorkCard {...props} caption={"Маршрутный лист с отметкой о сдаче документов"}>
            <div>Прикрепите фото маршрутного листа с отметкой о сдаче документов</div>
            <div className="my-2 text-center fw-bold">Или</div>
            <div>Прикрепите квитанцию о сдаче пакета документов через транспортную компанию</div>
        </NextWorkCard>
    );
};
