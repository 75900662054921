import axiosActions from "../actions/axiosActions";
import { API_URL } from "../constants";
import { Response } from "../models/response";
import { FileInfo } from "../models/routes/fileInfo";

interface AttachmentsService {
    download: (guid: string) => Promise<Response<File>>;
    remove: (guid: string) => Promise<Response<null>>;
    upload: (file: File, id: string, type?: string, fileName?: string) => Promise<Response<FileInfo>>;
}

const download = async (guid: string) => {
    const response = await axiosActions.getAsync<File>(`${API_URL.attachments.download}/${guid}`, true);
    return response;
};

const remove = async (guid: string) => await axiosActions.deleteAsync<null>(`${API_URL.attachments.remove}/${guid}`);

const upload = async (file: File, id: string, type?: string, fileName?: string) => {
    const body = new FormData();
    body.append("file", file, fileName);
    body.append("id", id);
    if (type) body.append("type", type);
    const response = await axiosActions.postAsync<FormData, FileInfo>(API_URL.attachments.upload, body);
    return response;
};

const attachmentsService: AttachmentsService = { download, remove, upload };

export default attachmentsService;
