import { buildAuthHeader, buildRequestUrl } from "../../setup/setupAxios";
import { API_URL } from "../../constants";

interface MobileService {
    startLocationService: (routeId: string, accessToken: string) => boolean;
    stopLocationService: () => void;
}

const startLocationService = (routeId: string, accessToken: string) => {
    const url = buildRequestUrl(`${API_URL.routes.get}/${routeId}/geoPositions`);
    const data = { url: url, token: buildAuthHeader(accessToken) };
    return window.android?.startLocationService(JSON.stringify(data)) ?? true;
};

const stopLocationService = () => {
    window.android?.stopLocationService();
};

const androidService: MobileService = { startLocationService, stopLocationService };

export default androidService;
