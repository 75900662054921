import clsx from "clsx";
import { ChangeEvent } from "react";
import InputMask from "react-input-mask";
import { Input } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

type Props = {
    value?: string | number;
    name?: string;
    type?: InputType;
    placeholder?: string;
    size?: "lg" | "sm";
    required?: boolean;
    autoFocus?: boolean;
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    mask: string;
    maskChar: string;
};

const MaskInput: React.FC<Props> = ({
    value,
    name,
    type,
    placeholder,
    size,
    required,
    autoFocus,
    className,
    onChange,
    mask,
    maskChar,
}) => {
    return (
        <Input
            value={value}
            name={name}
            placeholder={placeholder}
            type={type}
            bsSize={size || "lg"}
            required={required}
            autoFocus={autoFocus}
            className={clsx(className)}
            onChange={onChange}
            mask={mask}
            maskChar={maskChar}
            tag={InputMask}
        />
    );
};

export default MaskInput;
