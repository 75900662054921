import axiosActions from "../actions/axiosActions";
import { API_URL } from "../constants";
import { LoginRequest } from "../models/auth/loginRequest";
import { LoginResponse } from "../models/auth/loginResponse";
import { SignInRequest } from "../models/auth/signInRequest";
import { TimeoutResponse } from "../models/auth/timeoutResponse";
import { Response } from "../models/response";

interface AuthService {
    checkTimeout: (phone: string) => Promise<Response<TimeoutResponse>>;
    login: (phone: string, code: string) => Promise<Response<LoginResponse>>;
    signIn: (phone: string) => Promise<Response<null>>;
}

const checkTimeout = async (phone: string) => {
    const request: SignInRequest = { phone: phone.replaceAll(" ", "") };
    const response = await axiosActions.postAsync<SignInRequest, TimeoutResponse>(API_URL.auth.checkTimeout, request);
    return response;
};

const login = async (phone: string, code: string) => {
    const request: LoginRequest = { phone: phone.replaceAll(" ", ""), code };
    const response = await axiosActions.postAsync<LoginRequest, LoginResponse>(API_URL.auth.login, request);
    return response;
};

const signIn = async (phone: string) => {
    const request: SignInRequest = { phone: phone.replaceAll(" ", "") };
    const response = await axiosActions.postAsync<SignInRequest, null>(API_URL.auth.signIn, request);
    return response;
};

const authService: AuthService = { checkTimeout, login, signIn };

export default authService;
