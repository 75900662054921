import RowContent from "../../components/containers/rowContent";
import { RoutePoint } from "../../models/routes/routePoint";

type Props = {
    routePoint: RoutePoint;
};

const RoutePointInfo: React.FC<Props> = ({ routePoint }) => {
    return (
        <>
            <RowContent caption="Адрес" bold>
                {routePoint.city}, {routePoint.address}
            </RowContent>
            {routePoint.dateTime && (
                <RowContent caption="Время" bold>
                    {routePoint.dateTime}
                </RowContent>
            )}
            <RowContent caption="Контакт">
                <div className="fw-bold text-decoration-underline">{routePoint.phone}</div>
                <div>{routePoint.person}</div>
                <div>{routePoint.name}</div>
            </RowContent>
            {routePoint.info && <RowContent caption="Инфо">{routePoint.info}</RowContent>}
        </>
    );
};

export default RoutePointInfo;
