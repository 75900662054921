import clsx from "clsx";
import { Col, Row } from "reactstrap";

type Props = {
    caption: string;
    bold?: boolean;
    colon?: boolean;
    children: React.ReactNode;
};

const RowContent: React.FC<Props> = ({ caption, bold, colon = true, children }) => {
    return (
        <Row className="gx-2">
            <Col xs={3}>
                {caption}
                {colon ? ":" : null}
            </Col>
            <Col className={clsx(bold && "fw-bold")}>{children}</Col>
        </Row>
    );
};

export default RowContent;
