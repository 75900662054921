import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
    isOpen: boolean;
    header?: string;
    confirmButtonColor?: "primary" | "danger";
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmDialog = ({
    isOpen,
    header = "Предупреждение",
    confirmButtonColor = "primary",
    onClose,
    onConfirm,
    children,
}: Props) => {
    return (
        <Modal centered isOpen={isOpen} toggle={onClose}>
            <ModalHeader toggle={onClose}>{header}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter className="adaptive-mobile">
                <Col>
                    <Button onClick={onClose}>Отмена</Button>
                </Col>
                <Col>
                    <Button color={confirmButtonColor} onClick={onConfirm}>
                        Да
                    </Button>
                </Col>
            </ModalFooter>
        </Modal>
    );
};
