import React, { useState } from "react";
import { Alert, Button } from "reactstrap";
import LoadingButton from "../../components/buttons/loadingButton";
import ListItemCard from "../../components/containers/listItemCard";
import { RoutePointStatus } from "../../models/enums/routePointStatus";
import { CheckListParameters, mayBeClosed } from "../../models/routes/checkListParameters";
import { RoutePoint } from "../../models/routes/routePoint";
import attachmentsService from "../../services/attachmentsService";
import routePointsService from "../../services/routePointsService";
import CheckListAction from "./checkListAction";
import RoutePointInfo from "./routePointInfo";
import { FileInfo } from "../../models/routes/fileInfo";

type Props = {
    routePoint: RoutePoint;
    isFirstPoint: boolean;
    onDoneButtonClick: () => Promise<void>;
    onClose: () => void;
    onChangePoint: (point: RoutePoint) => void;
};

const CheckList = ({ routePoint, isFirstPoint, onDoneButtonClick, onClose, onChangePoint }: Props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false);

    const getCloseButtonCaption = () => {
        return closed && !actionInProgress
            ? isFirstPoint
                ? "Груз был забран"
                : "Адрес закрыт"
            : isFirstPoint
            ? "Груз забран"
            : "Закрыть адрес";
    };

    const handleDoneButtonClick = async () => {
        setActionInProgress(true);
        await onDoneButtonClick();
        setActionInProgress(false);
        showAlert && setShowAlert(false);
    };

    const handleAddEvent = async (eventActionType: string) => {
        await routePointsService.addEvent(routePoint.orderId, eventActionType);
    };

    const handleAddFiles = async function* (files: File[], parameters: CheckListParameters, index: number) {
        !showAlert && !closed && setShowAlert(true);

        const addedFiles: FileInfo[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = `${parameters.n}_${parameters.name}_${file.name}`;

            const { isOk, data: fileInfo } = await attachmentsService.upload(
                file,
                routePoint.orderId,
                parameters.attachmentType,
                fileName,
            );
            if (!isOk || !fileInfo) continue;

            fileInfo.data = file;
            fileInfo.name = file.name; // Убираем префикс, который добавили для сохранения фото

            addedFiles.push(fileInfo);

            const newPoint = { ...routePoint, checkList: [...routePoint.checkList!] };
            const newParameters = { ...routePoint.checkList![index] };
            newParameters.files = newParameters.files ? [...newParameters.files, ...addedFiles] : [...addedFiles];
            newPoint.checkList![index] = newParameters;

            onChangePoint(newPoint);

            yield i + 1;
        }
    };

    const handleRemoveFiles = async (files: FileInfo[], index: number) => {
        let actualFiles = [...routePoint.checkList![index].files!];

        for (const file of files) {
            const { isOk } = await attachmentsService.remove(file.guid);
            if (!isOk) continue;

            actualFiles = actualFiles?.filter((f) => f.guid !== file.guid);

            const newPoint = { ...routePoint, checkList: [...routePoint.checkList!] };
            newPoint.checkList![index] = { ...routePoint.checkList![index], files: actualFiles };

            onChangePoint(newPoint);
        }
    };

    const handleSaveActionParams = async (changes: Record<string, string>, index: number) => {
        const { isOk, data: newPoint } = await routePointsService.patch(routePoint.orderId, changes);
        if (!isOk || !newPoint) return;

        !showAlert && !closed && setShowAlert(true);

        // Оставляем ранее загруженные пользователем файлы, чтобы не загружать их повторно
        if (newPoint.checkList && newPoint.checkList.length > 0) {
            for (let i = 0; i < newPoint.checkList.length; i++) {
                const userUploadFiles = routePoint.checkList![i].files;
                if (userUploadFiles && userUploadFiles.length > 0) newPoint.checkList[i].files = userUploadFiles;
            }
        }

        newPoint.status = routePoint.status; // Сохраняем текущий статус маршрута
        onChangePoint(newPoint);
    };

    const closed = routePoint.status === RoutePointStatus.Closed;
    const routeMayBeClosed = routePoint.checkList?.every((i) => mayBeClosed(i)) ?? true;

    const lastOpenActionIndex = routeMayBeClosed
        ? Number.MAX_VALUE
        : routePoint.checkList?.findIndex((i) => !mayBeClosed(i)) ?? -1;

    return (
        <div>
            <Alert isOpen={showAlert} color="danger" className="position-sticky p-2 small" style={{ top: 10, zIndex: 999 }}>
                Вы начали выполнять действия на точке. Не забудьте закрыть адрес сразу после выполнения последнего действия
            </Alert>
            <ListItemCard>
                <RoutePointInfo routePoint={routePoint} />
            </ListItemCard>
            <h6 className="mt-4 text-center fw-bold">Действия:</h6>
            {routePoint.checkList?.map((item, index) => (
                <CheckListAction
                    key={index}
                    parameters={item}
                    actionNotAvailable={index > lastOpenActionIndex}
                    onAddEvent={handleAddEvent}
                    onAddFiles={(files, parameters) => handleAddFiles(files, parameters, index)}
                    onRemoveFiles={closed ? undefined : (files) => handleRemoveFiles(files, index)}
                    onSaveActionParams={(changes) => handleSaveActionParams(changes, index)}
                />
            ))}
            <LoadingButton
                caption={getCloseButtonCaption()}
                loading={actionInProgress}
                disabled={actionInProgress || !routeMayBeClosed || closed}
                className="w-100 mt-3"
                color={closed ? "secondary" : "primary"}
                onClick={handleDoneButtonClick}
            />
            <Button className="w-100 mt-5 mb-4" size="lg" onClick={onClose}>
                К списку адресов &lt;&lt;
            </Button>
        </div>
    );
};

export default CheckList;
