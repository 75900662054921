import { faArrowRotateRight } from "../../fontawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState } from "react";

type Props = {
    children: React.ReactNode;
    onRefresh: () => Promise<void>;
    className?: string;
};

const MAX_PULL_LENGTH = 250;
const PULL_LENGTH_FOR_REFRESH = 220;
const MARGIN_COEF = 3.118;
const REFRESH_ICON = faArrowRotateRight;

const PullToRefreshContainer: React.FC<Props> = ({ children, onRefresh, className }) => {
    const [startCoordY, setStartCoordY] = useState(0);
    const [pullLength, setPullLength] = useState(0);
    const [refreshActionInProgress, setRefreshActionInProgress] = useState(false);

    const handleTouchStart = (e: React.TouchEvent) => {
        const { screenY } = e.targetTouches[0];
        setStartCoordY(screenY);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const { screenY } = e.targetTouches[0];
        const currentPullLength = startCoordY < screenY ? Math.abs(screenY - startCoordY) : 0;

        if (currentPullLength < MAX_PULL_LENGTH && !refreshActionInProgress) setPullLength(currentPullLength);
    };

    const handleTouchEnd = async () => {
        setStartCoordY(0);
        setPullLength(0);

        if (pullLength > PULL_LENGTH_FOR_REFRESH) {
            setRefreshActionInProgress(true);
            await onRefresh();
            setRefreshActionInProgress(false);
        }
    };

    const marginTop = pullLength / MARGIN_COEF || 0;

    return (
        <div
            className={clsx("position-relative", className)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ marginTop: marginTop }}
        >
            {refreshActionInProgress ? (
                <div className="w-100 text-center" style={{ marginBottom: 6 }}>
                    <FontAwesomeIcon icon={REFRESH_ICON} className="text-secondary" size="xl" spin />
                </div>
            ) : (
                <div
                    className={clsx("position-absolute w-100 text-center", marginTop > 10 ? "d-block" : "d-none")}
                    style={{ top: "-30px" }}
                >
                    <FontAwesomeIcon
                        icon={REFRESH_ICON}
                        className="text-secondary"
                        size="xl"
                        style={{ transform: `rotate(${pullLength}deg)` }}
                    />
                </div>
            )}
            {children}
        </div>
    );
};

export default PullToRefreshContainer;
