import { faFile, faFileImage, faFileVideo, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";
import { FileInfo } from "../../../models/routes/fileInfo";

const getFileIcon = (fileType: string) => {
    if (fileType.startsWith("image")) return faFileImage;
    if (fileType.startsWith("video")) return faFileVideo;
    return faFile;
};

type Props = {
    file: FileInfo;
    number: number;
    disabled?: boolean;
    onRemove?: () => void;
};

const FileItem = ({ file, number, disabled, onRemove }: Props) => {
    return (
        <Row className={"gx-0 my-2 align-items-center"}>
            <Col>
                {number}.
                <FontAwesomeIcon icon={getFileIcon(file.type)} className="mx-1" />
                {file.name}
            </Col>
            {onRemove && (
                <Col xs={"auto"}>
                    <Button size={"sm"} color={"danger"} disabled={disabled} className={"px-2 py-1"} onClick={onRemove}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Col>
            )}
        </Row>
    );
};

export default FileItem;
