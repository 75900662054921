import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { Button, Col, Row } from "reactstrap";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import { FileInfo } from "../../models/routes/fileInfo";
import attachmentsService from "../../services/attachmentsService";
import { ImageGalleryItem } from "./imageGallery/types/imageGalleryItem";
import { getImageParams } from "./imageGallery/helpers";
import "./imageGallery/imageGalleryStyles.css";
import { pluralFile } from "../../lib/heplers/plural";
import { ConfirmDialog } from "../dialogs/confirmDialog";

const SHOW_SIZE = 3;

type Props = {
    images: FileInfo[];
    disabled?: boolean;
    onRemoveFiles?: (files: FileInfo[]) => Promise<void>;
};

const ImageGallery = ({ images, disabled, onRemoveFiles }: Props) => {
    const [galleryHeight] = useState(
        parseInt(getComputedStyle(document.documentElement).getPropertyValue("--image-gallery-height")),
    );
    const galleryParentRef = useRef<HTMLDivElement>(null);

    const [items, setItems] = useState<ImageGalleryItem[]>([]);
    const [index, setIndex] = useState(-1);
    const [showAllItems, setShowAllItems] = useState(false);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const hasLastFiles = images.length > SHOW_SIZE;
    const selectedFiles = items.filter((i: any) => i.isSelected);
    const hasSelectedFiles = selectedFiles.length > 0;
    const enableRemoveFiles = Boolean(onRemoveFiles);

    // Убираем стандартный title на английском языке - библиотека не позволяет его переопределить
    useEffect(() => {
        if (!galleryParentRef?.current) return;

        const elements = galleryParentRef.current.getElementsByClassName("ReactGridGallery_tile-icon-bar");
        for (let i = 0; i < elements.length; i++) {
            const imageItem = elements[i];
            if (imageItem.children.length > 0) {
                imageItem.children[0].setAttribute("title", "Выбрать фото");
            }
        }
    }, [items]);

    // Ссылка для получения актуального значения items внутри useEffect без добавления items в зависимости useEffect
    const itemsRef = useRef(items);
    useEffect(() => {
        itemsRef.current = items;
    });

    useEffect(() => {
        (async () => {
            // Убираем удалённые файлы
            setItems((currentItems) => currentItems.filter((item) => images.some((img) => item.info.guid === img.guid)));

            const loadableImages = images
                .filter((img) => itemsRef.current.every((item) => item.info.guid !== img.guid))
                .slice(0, showAllItems ? undefined : SHOW_SIZE);

            for (let i = 0; i < loadableImages.length; i++) {
                const imgInfo = loadableImages[i];
                const imageFile = imgInfo.data ?? (await attachmentsService.download(imgInfo.guid)).data;

                if (!imageFile) continue;

                const imageParams = await getImageParams(imageFile);

                const newItem: ImageGalleryItem = {
                    src: imageParams.src,
                    width: imageParams.width,
                    height: imageParams.height,
                    isSelected: false,
                    title: imgInfo.name,
                    info: imgInfo,
                };

                setItems((currentItems) => [...currentItems, newItem]);
            }
        })();
    }, [images, showAllItems]);

    const handleSelect = (selectedIndex: number) => {
        const newItems = items.map((item, i) =>
            i === selectedIndex
                ? {
                      ...item,
                      isSelected: !item.isSelected,
                  }
                : item,
        );
        setItems(newItems);
    };

    const handleRemove = async () => {
        setIsOpenConfirmDialog(false);
        setIsRemoving(true);
        await onRemoveFiles?.(selectedFiles.map((f) => f.info));
        setIsRemoving(false);
    };

    return (
        <div
            ref={galleryParentRef}
            className={clsx("overflow-hidden gallery-parent", !enableRemoveFiles && "hide-select-button")}
        >
            <Gallery
                images={showAllItems ? items : items.slice(0, SHOW_SIZE)}
                enableImageSelection={enableRemoveFiles && !isRemoving && !disabled}
                rowHeight={galleryHeight}
                onClick={(index) => setIndex(index)}
                onSelect={handleSelect}
            />
            {hasSelectedFiles && (
                <Row className={"mt-3"}>
                    <Col xs={12}>
                        <Button
                            color="danger"
                            disabled={disabled || isRemoving}
                            className={"w-100"}
                            onClick={() => setIsOpenConfirmDialog(true)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} /> {pluralFile(selectedFiles.length)}
                        </Button>
                    </Col>
                </Row>
            )}
            {hasLastFiles && (
                <Row xs="auto" className="gx-0 mt-2 justify-content-center align-items-center">
                    <Col>Всего {pluralFile(images.length)}.</Col>
                    <Col>
                        <Button color="link" onClick={() => setShowAllItems(!showAllItems)}>
                            {showAllItems ? "Скрыть последние" : "Показать все"}
                        </Button>
                    </Col>
                </Row>
            )}
            <Lightbox slides={items} open={index >= 0} index={index} plugins={[Captions, Zoom]} close={() => setIndex(-1)} />
            <ConfirmDialog
                isOpen={isOpenConfirmDialog}
                header={"Удаление файлов"}
                confirmButtonColor={"danger"}
                onClose={() => setIsOpenConfirmDialog(false)}
                onConfirm={handleRemove}
            >
                Вы уверены, что хотите удалить выбранные файлы?
            </ConfirmDialog>
        </div>
    );
};

export default ImageGallery;
