import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginPage";
import RoutesPage from "./pages/routesPage";
import LoginLayout from "./layouts/loginLayout";
import MainLayout from "./layouts/mainLayout";
import RoutePage from "./pages/routePage";
import { useClearCacheCtx } from "react-clear-cache";
import { useAppSelector } from "./hooks";

type Props = {
    basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    if (!isLatestVersion) emptyCacheStorage();

    const isAuthorized = Boolean(useAppSelector((store) => store.auth.accessToken));

    return (
        <BrowserRouter basename={basename}>
            <Routes>
                {isAuthorized ? (
                    <Route element={<MainLayout />}>
                        <Route path="/" element={<RoutesPage />} />
                        <Route path="/routes" element={<RoutesPage />} />
                        <Route path="/routes/:routeId" element={<RoutePage />} />
                        <Route path="/login" element={<Navigate replace to="/" />} />
                    </Route>
                ) : (
                    <Route element={<LoginLayout />}>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="*" element={<Navigate replace to="/login" />} />
                    </Route>
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default App;
