import { useState } from "react";
import PhoneForm from "./phoneForm";
import CodeForm from "./codeForm";

enum LoginStage {
    InputPhone,
    InputCode,
}

export default function LoginPage() {
    const [stage, setStage] = useState(LoginStage.InputPhone);
    const [phone, setPhone] = useState("");

    const handleRequestNewCode = () => {
        setPhone("");
        setStage(LoginStage.InputPhone);
    };

    switch (stage) {
        case LoginStage.InputPhone:
            return (
                <PhoneForm
                    phone={phone}
                    onChangePhone={(phone) => setPhone(phone)}
                    onSubmitPhone={() => setStage(LoginStage.InputCode)}
                />
            );
        case LoginStage.InputCode:
            return <CodeForm phone={phone} onRequestNewCode={handleRequestNewCode} />;
    }
}
