import { Outlet } from "react-router-dom";
import { Container } from "reactstrap";

type Props = {
    children?: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
    return (
        <Container className="h-100 d-flex flex-column">
            <div className="mt-4 mb-3 text-center">
                <img src={`${process.env.PUBLIC_URL}/media/logo.svg`} style={{ height: 50 }} alt="versta logo" />
                <img src={`${process.env.PUBLIC_URL}/media/dsc-logo.svg`} style={{ height: 50 }} alt="dsc logo" />
            </div>
            <div style={{ flexGrow: 1 }}>{children ? children : <Outlet />}</div>
        </Container>
    );
};

export default MainLayout;
