import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import UniSpin from "../uniSpin";

type Props = {
    onLoading: () => Promise<void>;
    caption?: string;
    notFoundInfo?: React.ReactNode;
    children: React.ReactNode;
};

const LoadingContainer: React.FC<Props> = ({ onLoading, caption, notFoundInfo, children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await onLoading();
            setIsLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateButtonClick = async () => {
        setIsLoading(true);
        await onLoading();
        setIsLoading(false);
    };

    return isLoading ? (
        <UniSpin size="4x" caption={caption} />
    ) : (
        <>
            {Boolean(notFoundInfo) ? (
                <div className="view-center">
                    <div>
                        {notFoundInfo}
                        <Button className="mt-4 w-100" size="lg" color="primary" onClick={handleUpdateButtonClick}>
                            Обновить
                        </Button>
                    </div>
                </div>
            ) : (
                children
            )}
        </>
    );
};

export default LoadingContainer;
