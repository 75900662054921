import { NextWorkCard, NextWorkCardProps } from "./nextWorkCard";

type Props = Omit<NextWorkCardProps, "caption">;

export const SignedDocumentsCard = (props: Props) => {
    return (
        <NextWorkCard {...props} caption={"Фото подписанных документов"}>
            <div>Для закрытия маршрута прикрепите фото подписанных документов после возврата велосипедов в мастерской</div>
        </NextWorkCard>
    );
};
