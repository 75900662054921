import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "../fontawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type Props = {
    size?: SizeProp;
    className?: string;
    caption?: string;
    captionClassName?: string;
};

const UniSpin: React.FC<Props> = ({ size, className, caption, captionClassName }) => {
    return (
        <div className={clsx("text-center", className)}>
            <FontAwesomeIcon icon={faCircleNotch} size={size || "1x"} className="text-primary" spin />
            {caption && <div className={clsx(captionClassName)}>{caption}...</div>}
        </div>
    );
};

export default UniSpin;
