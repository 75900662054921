import clsx from "clsx";
import { parse, toSeconds } from "iso8601-duration";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import UniTimer from "../../components/timers/uniTimer";
import authService from "../../services/authService";

type Props = {
    phone: string;
    onRequestNewCode: () => void;
    className?: string;
};

const RequestNewCodeButton: React.FC<Props> = ({ phone, onRequestNewCode, className }) => {
    const [showButton, setShowButton] = useState(false);
    const [timeoutInSeconds, setTimeoutInSeconds] = useState(600);

    useEffect(() => {
        (async () => {
            const result = await authService.checkTimeout(phone);
            if (result.data?.timeout) setTimeoutInSeconds(toSeconds(parse(result.data.timeout)));
        })();
    }, [phone]);

    return (
        <div className={clsx(className)} style={{ minHeight: 48 }}>
            {showButton ? (
                <div>
                    <Button color="primary" size="lg" className="w-100" onClick={onRequestNewCode}>
                        Отправить повторно
                    </Button>
                </div>
            ) : (
                <div>
                    Повторная отправка кода возможна через&nbsp;
                    <UniTimer seconds={timeoutInSeconds} onComplete={() => setShowButton(true)} />
                </div>
            )}
        </div>
    );
};

export default RequestNewCodeButton;
