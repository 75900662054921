import LoadingButton from "./loadingButton";

type Props = {
    caption: string;
    isSubmitting: boolean;
    disabled?: boolean;
    size?: "lg" | "sm" | " ";
    className?: string;
    onSubmit?: (event: React.FormEvent) => void;
};

const SubmitButton: React.FC<Props> = ({ caption, isSubmitting, disabled, size, className, onSubmit }) => {
    return (
        <LoadingButton
            caption={caption}
            loading={isSubmitting}
            disabled={disabled}
            size={size || "lg"}
            color="primary"
            type="submit"
            className={className}
            onClick={onSubmit}
        />
    );
};

export default SubmitButton;
