import clsx from "clsx";
import { ChangeEvent, ReactElement, useRef, useState } from "react";
import { Button } from "reactstrap";
import { FileInfo } from "../../../models/routes/fileInfo";
import UniSpin from "../../uniSpin";
import FileItems from "./fileItems";

type Props = {
    files: FileInfo[];
    accept?: string;
    multiple?: boolean;
    disabled?: boolean;
    className?: string;
    caption?: string;
    buttonColor?: string;
    onAddFiles: (files: File[]) => AsyncGenerator<any, void>;
    onRemoveFiles?: (file: FileInfo[]) => Promise<void>;
    renderFileItems?: (
        files: FileInfo[],
        onRemoveFiles?: (files: FileInfo[]) => Promise<void>,
        disabled?: boolean,
    ) => ReactElement;
};

const FileInput = ({
    files,
    accept,
    multiple,
    disabled,
    className,
    caption,
    buttonColor,
    onAddFiles,
    onRemoveFiles,
    renderFileItems,
}: Props) => {
    const [filesLoaded, setFilesLoaded] = useState(false);
    const [loadingCaption, setLoadingCaption] = useState("");

    const inputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => inputRef?.current?.click();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setFilesLoaded(true);

        if (!onAddFiles) return;

        const files = Array.from(event.target.files || []);
        const isManyFiles = files.length > 1;
        let uploadFileCount = 0;

        isManyFiles && setLoadingCaption(`Обработано ${uploadFileCount} файлов из ${files.length}`);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for await (const i of onAddFiles(files)) {
            isManyFiles && setLoadingCaption(`Обработано ${++uploadFileCount} файлов из ${files.length}`);
        }

        setFilesLoaded(false);
        setLoadingCaption("");

        // Сброс значения выбранного файла для возможной повторной загрузки
        inputRef.current!.value = "";
    };

    const handleRemoveFiles = onRemoveFiles
        ? async (file: FileInfo[]) => {
              setFilesLoaded(true);
              await onRemoveFiles(file);
              setFilesLoaded(false);
          }
        : undefined;

    const handleRemoveFile = handleRemoveFiles ? async (file: FileInfo) => await handleRemoveFiles?.([file]) : undefined;

    return (
        <div className={clsx(className)}>
            <div className="mb-2">
                {renderFileItems?.(files, disabled ? undefined : handleRemoveFiles, filesLoaded) ?? (
                    <FileItems files={files} disabled={filesLoaded} onRemove={disabled ? undefined : handleRemoveFile} />
                )}
            </div>
            {filesLoaded && <UniSpin className="mb-2" caption={loadingCaption} />}
            <input
                ref={inputRef}
                type="file"
                className="d-none"
                accept={accept}
                multiple={multiple}
                disabled={disabled || filesLoaded}
                onChange={handleChange}
            />
            <Button onClick={handleButtonClick} className="w-100" disabled={disabled || filesLoaded} color={buttonColor}>
                {caption || "Добавить файлы"}
            </Button>
        </div>
    );
};

export default FileInput;
