import { Badge } from "reactstrap";
import ListItemCard from "../../components/containers/listItemCard";
import RowContent from "../../components/containers/rowContent";
import { RouteStatus } from "../../models/enums/routeStatus";
import { isRouteByOneOrder, Route } from "../../models/routes/route";
import RouteActions from "./routeActions";

const colorClassNameDict = new Map<RouteStatus, string>([
    [RouteStatus.New, "warning"],
    [RouteStatus.Confirmed, "warning"],
    [RouteStatus.InWork, "danger"],
    [RouteStatus.Canceled, "secondary"],
    [RouteStatus.WaitingForRouteSheet, "warning"],
    [RouteStatus.Finished, "success"],
]);

type Props = {
    route: Route;
    onRouteChange: (route: Route) => void;
};

const RouteView: React.FC<Props> = ({ route, onRouteChange }) => {
    return (
        <ListItemCard className={"mt-2 mb-3"}>
            <div>
                <span className="me-3 fw-bold">{route.orderId}</span>
                <Badge pill color={colorClassNameDict.get(route.status) || "warning"}>
                    {route.statusText}
                </Badge>
            </div>
            <RowContent caption="Маршрут" bold>
                {route.from} - {route.to}
            </RowContent>
            {!isRouteByOneOrder(route) && (
                <RowContent caption="Точек" bold>
                    {route.points.length}
                    {route.pointsComment && <> / {route.pointsComment}</>}
                </RowContent>
            )}
            <RowContent caption="Погрузка">{route.fromAddress}</RowContent>
            <RowContent caption="Время" bold>
                {route.takeTime} / {route.takeDate}
            </RowContent>
            {route.comments && <RowContent caption={"Инфо"}>{route.comments}</RowContent>}
            <RouteActions route={route} onRouteChange={onRouteChange} />
        </ListItemCard>
    );
};

export default RouteView;
