import { Action, configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import rootSaga from "./saga";

export interface ActionWithPayload<T> extends Action {
    payload?: T;
}

export const ACTION_TYPES = {
    Authorize: "[Authorize] Action",
    Unauthorize: "[Unauthorize] Action",
};

export type AuthState = {
    accessToken?: string;
};

const initialAuthState: AuthState = {
    accessToken: undefined,
};

export const actions = {
    authorize: (accessToken: string) => ({ type: ACTION_TYPES.Authorize, payload: { accessToken } }),
    unauthorize: () => ({ type: ACTION_TYPES.Unauthorize }),
};

const authReducer = persistReducer(
    { storage, key: "auth", whitelist: ["userId", "accessToken"] },
    (state: AuthState = initialAuthState, action: ActionWithPayload<AuthState>) => {
        switch (action.type) {
            case ACTION_TYPES.Authorize:
                return { ...state, accessToken: action.payload?.accessToken };
            case ACTION_TYPES.Unauthorize:
                return initialAuthState;
            default:
                return state;
        }
    },
);

export const rootReducer = combineReducers({
    auth: authReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
