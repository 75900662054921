import { useEffect, useState } from "react";
import LoadingButton from "../../../components/buttons/loadingButton";
import UniInput from "../../../components/inputs/uniInput";
import { CheckListParameters, mayBeClosed } from "../../../models/routes/checkListParameters";

type Props = {
    parameters: CheckListParameters;
    buttonColor?: string;
    disabled?: boolean;
    onSave: (changes: Record<string, string>) => Promise<void>;
};

const InputAction: React.FC<Props> = ({ parameters, buttonColor, disabled, onSave }) => {
    const [value, setValue] = useState(parameters.value || "");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue(parameters.value || "");
    }, [parameters.value]);

    const handleButtonClick = async () => {
        setLoading(true);
        await onSave({ [parameters.propertyName!]: value });
        setLoading(false);
    };

    return (
        <div className="mt-2">
            <UniInput
                value={value}
                name={parameters.propertyName}
                type="number"
                disabled={disabled}
                min={1}
                step={1}
                onChange={({ target: { value } }) => setValue(value)}
            />
            <LoadingButton
                caption={mayBeClosed(parameters) ? "Изменить" : "Сохранить"}
                loading={loading}
                color={buttonColor}
                className="mt-2 w-100"
                disabled={disabled}
                onClick={handleButtonClick}
            />
        </div>
    );
};

export default InputAction;
