import clsx from "clsx";
import { useState } from "react";
import PullToRefreshContainer from "../../components/containers/pullToRefreshContainer";
import LoadingContainer from "../../components/containers/loadingContainer";
import { Route } from "../../models/routes/route";
import routesService from "../../services/routesService";
import RouteView from "./routeView";

export default function RoutesPage() {
    const [groupRoutes, setGroupRoutes] = useState<Record<string, Route[]>>({});

    const handleRouteChange = (route: Route, index: number, key: string) => {
        const newRoutes = { ...groupRoutes };
        newRoutes[key][index] = route;
        setGroupRoutes(newRoutes);
    };

    const handleRefreshRoutes = async () => {
        const routesResponse = await routesService.get();
        setGroupRoutes(routesResponse.isOk ? routesResponse.data! : {});
    };

    const noRoutesInfo =
        Object.entries(groupRoutes)?.length > 0 ? undefined : (
            <>
                <div className="text-center">
                    Активных маршрутов <span className="fw-bold">не найдено!</span>
                </div>
                <div className="text-center">Попробуйте обновить данные или свяжитесь с менеджером versta</div>
            </>
        );

    return (
        <LoadingContainer onLoading={handleRefreshRoutes} caption="Загрузка маршрутов" notFoundInfo={noRoutesInfo}>
            <PullToRefreshContainer onRefresh={handleRefreshRoutes}>
                {Object.entries(groupRoutes).map(([date, routes], groupIndex) => (
                    <div key={groupIndex}>
                        <h6 className={clsx("text-center fw-bold", groupIndex !== 0 && "mt-4")}>{date}</h6>
                        {routes.map((route, routeIndex) => (
                            <RouteView
                                key={routeIndex}
                                route={route}
                                onRouteChange={(route) => handleRouteChange(route, routeIndex, date)}
                            />
                        ))}
                    </div>
                ))}
            </PullToRefreshContainer>
        </LoadingContainer>
    );
}
