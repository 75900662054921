import { useState } from "react";
import { Form } from "reactstrap";
import SubmitButton from "../../components/buttons/submitButton";
import MaskInput from "../../components/inputs/maskInput";
import { actions } from "../../redux";
import authService from "../../services/authService";
import RequestNewCodeButton from "./requestNewCodeButton";
import { useAppDispatch } from "../../hooks";

type Props = {
    phone: string;
    onRequestNewCode: () => void;
};

const CodeForm: React.FC<Props> = ({ phone, onRequestNewCode }) => {
    const [code, setCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isWrongCode, setIsWrongCode] = useState(false);

    const dispatch = useAppDispatch();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // TODO: Валидация кода с помощью формика
        if (code.includes("X")) return;

        setIsSubmitting(true);

        const response = await authService.login(phone, code);
        if (response.isOk) dispatch(actions.authorize(response.data!.jwtToken));
        else setIsWrongCode(true);

        setIsSubmitting(false);
    };

    return (
        <Form onSubmit={handleSubmit} className="text-center">
            <h2>{isWrongCode ? "Неверный код!" : "Введите код из СМС"}</h2>
            <div className="mt-2">Мы отправили вам код подтверждения</div>
            <div className="my-4">
                {/* TODO: Сделать красивый ввод кода: каждый символ в отдельном input */}
                <MaskInput
                    value={code}
                    name="code"
                    type="tel"
                    placeholder="XXX-XXX"
                    required
                    className="text-center"
                    onChange={(event) => setCode(event.target.value)}
                    mask="999-999"
                    maskChar="X"
                />
            </div>
            <div>
                <SubmitButton caption="Проверить" isSubmitting={isSubmitting} className="w-100" />
            </div>
            <RequestNewCodeButton phone={phone} className="my-5" onRequestNewCode={onRequestNewCode} />
            <div>Не получили СМС?</div>
        </Form>
    );
};

export default CodeForm;
