import { Outlet } from "react-router-dom";
import { Container } from "reactstrap";

type Props = {
    children?: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({ children }) => {
    return (
        <Container className="view-center">
            <div>
                <div className="text-center">
                    <img src={`${process.env.PUBLIC_URL}/media/logo.svg`} style={{ height: 160 }} alt="versta logo" />
                </div>
                <div className="mt-2 mb-4 text-center">
                    <img src={`${process.env.PUBLIC_URL}/media/dsc-logo.svg`} style={{ height: 60 }} alt="dsc logo" />
                </div>
                <div>{children ? children : <Outlet />}</div>
            </div>
        </Container>
    );
};

export default LoginLayout;
