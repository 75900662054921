import { AxiosResponse, AxiosStatic, InternalAxiosRequestConfig } from "axios";
import { actions } from "../redux";

const API_URL = process.env.REACT_APP_API_URL;

export const buildRequestUrl = (url?: string) => `${API_URL}${url}`;
export const buildAuthHeader = (accessToken: string) => `Bearer ${accessToken}`;

export default function setupAxios(axios: AxiosStatic, store: any) {
    axios.interceptors.request.use(
        (request: InternalAxiosRequestConfig<any>) => {
            const {
                auth: { accessToken },
            } = store.getState();

            if (accessToken && request.headers) request.headers.Authorization = buildAuthHeader(accessToken);

            request.url = buildRequestUrl(request.url);

            return request;
        },
        (error: any) => Promise.reject(error),
    );

    axios.interceptors.response.use(
        (response: AxiosResponse<any, any>) => response,
        (error: any) => {
            if (error.response.status === 401) store.dispatch(actions.unauthorize());
            return Promise.reject(error);
        },
    );
}
