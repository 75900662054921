import { takeEvery } from "redux-saga/effects";
import { ACTION_TYPES } from "../index";
import androidService from "../../services/mobile/androidService";

function stopLocation() {
    androidService.stopLocationService();
}

function* watchMobile() {
    yield takeEvery(ACTION_TYPES.Unauthorize, stopLocation);
}

export { watchMobile };
