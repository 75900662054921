import axiosActions from "../actions/axiosActions";
import { API_URL } from "../constants";
import { RoutePointStatus } from "../models/enums/routePointStatus";
import { Response } from "../models/response";
import { RoutePoint } from "../models/routes/routePoint";

interface RoutePointsService {
    addEvent: (pointId: string, eventActionType: string) => Promise<boolean>;
    patch: (pointId: string, changes: Record<string, string>) => Promise<Response<RoutePoint>>;
    setStatus: (pointId: string, status: RoutePointStatus) => Promise<Response<RoutePoint>>;
}

const addEvent = async (pointId: string, eventActionType: string) => {
    const url = `${API_URL.routePoints.controller}/${pointId}/${API_URL.routePoints.events}`;
    const response = await axiosActions.postAsync(url, { eventActionType });
    return response.isOk;
};

const patch = async (pointId: string, changes: Record<string, string>) => {
    const response = await axiosActions.patchAsync<Record<string, string>, RoutePoint>(
        `${API_URL.routePoints.controller}/${pointId}`,
        changes,
    );

    return response;
};

const setStatus = async (pointId: string, status: RoutePointStatus) => await patch(pointId, { Status: status.toString() });

const routePointsService: RoutePointsService = { addEvent, patch, setStatus };

export default routePointsService;
