import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "far";
const iconName = "arrow-rotate-right";
const width = 512;
const height = 512;
const aliases = ["8635", "arrow-right-rotate", "arrow-rotate-forward", "redo"];
const unicode = "f01e";
const svgPathData =
    "M496 40v160C496 213.3 485.3 224 472 224h-160C298.8 224 288 213.3 288 200s10.75-24 24-24h100.5C382.8 118.3 322.5 80 256 80C158.1 80 80 158.1 80 256s78.97 176 176 176c41.09 0 81.09-14.47 112.6-40.75c10.16-8.5 25.31-7.156 33.81 3.062c8.5 10.19 7.125 25.31-3.062 33.81c-40.16 33.44-91.17 51.77-143.5 51.77C132.4 479.9 32 379.5 32 256s100.4-223.9 223.9-223.9c79.85 0 152.4 43.46 192.1 109.1V40c0-13.25 10.75-24 24-24S496 26.75 496 40z";

const faArrowRotateRight: IconDefinition = {
    prefix: prefix,
    iconName: iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};

export default faArrowRotateRight;
