import { RouteStatus } from "../../../models/enums/routeStatus";
import { NextWorkCard, NextWorkCardProps } from "./nextWorkCard";

type Props = Omit<NextWorkCardProps, "caption">;

export const WaybillAndTimeSpentCard = (props: Props) => {
    const caption =
        props.route.status === RouteStatus.Confirmed
            ? "Подтвердите начало маршрута"
            : props.route.status === RouteStatus.Finished
            ? "Маршрут завершен"
            : "Подтвердите завершение маршрута";
    return (
        <NextWorkCard {...props} caption={caption}>
            <div>Для закрытия маршрута введите время и прикрепите файл с транспортной накладной</div>
        </NextWorkCard>
    );
};
