import { ReactNode, useMemo } from "react";
import Countdown from "react-countdown";

type Props = {
    seconds: number;
    onComplete?: () => void;
    renderCompletedState?: () => ReactNode;
};

const UniTimer: React.FC<Props> = ({ seconds, onComplete, renderCompletedState }) => {
    const format = (number: number) => number.toString().padStart(2, "0");

    const date = useMemo(() => Date.now() + seconds * 1000, [seconds]);

    return (
        <Countdown
            date={date}
            onComplete={onComplete}
            renderer={({ minutes, seconds, completed }) => {
                return completed && renderCompletedState ? (
                    renderCompletedState()
                ) : (
                    <span>
                        {format(minutes)}:{format(seconds)}
                    </span>
                );
            }}
        />
    );
};

export default UniTimer;
