import { useState } from "react";
import { Form } from "reactstrap";
import SubmitButton from "../../components/buttons/submitButton";
import MaskInput from "../../components/inputs/maskInput";
import authService from "../../services/authService";

type Props = {
    phone: string;
    onChangePhone: (phone: string) => void;
    onSubmitPhone: () => void;
};

const PhoneForm: React.FC<Props> = ({ phone, onChangePhone, onSubmitPhone }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // TODO: Валидация телефона с помощью формика
        if (phone.includes("X")) return;

        setIsSubmitting(true);

        const response = await authService.signIn(phone);
        if (response.isOk) onSubmitPhone();

        setIsSubmitting(false);
    };

    return (
        <Form onSubmit={handleSubmit} className="text-center">
            <h2>Авторизация</h2>
            <div className="mt-2">
                Доступ для исполнителей по заказам&nbsp;
                <span className="fw-bold">versta</span>
            </div>
            <div className="my-4">
                <MaskInput
                    value={phone}
                    name="phone"
                    type="tel"
                    placeholder="Номер телефона"
                    required
                    className="text-center"
                    onChange={(event) => onChangePhone(event.target.value)}
                    mask="+7 999 999 99 99"
                    maskChar="X"
                />
            </div>
            <div>
                <SubmitButton caption="Получить код" isSubmitting={isSubmitting} className="w-100" />
            </div>
        </Form>
    );
};

export default PhoneForm;
