import clsx from "clsx";
import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { FileInfo } from "../../../models/routes/fileInfo";
import FileItem from "./fileItem";
import { ConfirmDialog } from "../../dialogs/confirmDialog";

const SHOW_SIZE = 3;

type Props = {
    files: FileInfo[];
    disabled?: boolean;
    className?: string;
    onRemove?: (file: FileInfo) => Promise<void>;
};

const FileItems = ({ files, disabled, className, onRemove }: Props) => {
    const [showAllItems, setShowAllItems] = useState(false);
    const [fileToRemove, setFileToRemove] = useState<FileInfo | null>(null);
    const [isRemoving, setIsRemoving] = useState(false);

    const firstFiles = files.slice(0, SHOW_SIZE);
    const lastFiles = files.slice(SHOW_SIZE);

    const hasLastFiles = lastFiles.length > 0;

    const handleRemove = async () => {
        const file = fileToRemove;
        setIsRemoving(true);
        setFileToRemove(null);
        await onRemove?.(file!);
        setIsRemoving(false);
    };

    return (
        <div className={clsx(className)}>
            {firstFiles.map((file, index) => (
                <FileItem
                    key={index}
                    file={file}
                    number={index + 1}
                    disabled={disabled || isRemoving}
                    onRemove={onRemove ? () => setFileToRemove(file) : undefined}
                />
            ))}
            {hasLastFiles && !showAllItems && <div className="text-center">...</div>}
            <Collapse isOpen={showAllItems}>
                {lastFiles.map((file, index) => (
                    <FileItem
                        key={index}
                        file={file}
                        number={SHOW_SIZE + index + 1}
                        disabled={disabled || isRemoving}
                        onRemove={onRemove ? () => setFileToRemove(file) : undefined}
                    />
                ))}
            </Collapse>
            {hasLastFiles && (
                <Row xs="auto" className="gx-0 justify-content-center align-items-center">
                    <Col>Всего {files.length} файлов. </Col>
                    <Col>
                        <Button color="link" onClick={() => setShowAllItems(!showAllItems)}>
                            {showAllItems ? "Скрыть последние" : "Показать все"}
                        </Button>
                    </Col>
                </Row>
            )}
            <ConfirmDialog
                isOpen={fileToRemove !== null}
                header={"Удаление файла"}
                confirmButtonColor={"danger"}
                onClose={() => setFileToRemove(null)}
                onConfirm={handleRemove}
            >
                Вы уверены, что хотите удалить файл?
            </ConfirmDialog>
        </div>
    );
};

export default FileItems;
